<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 pa-6>
            <span style="font-weight: bold" class="mainheadig"
              >Stories of Impact</span
            ></v-flex
          >
          <v-flex xs12 pb-5 ref="featuredSupport" class="featured-support">
            <v-btn color="#e27725">
              <span class="popregular" style="color: white">
                FEATURED SUPPORT
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs12 pt-10 pb-10>
            <v-layout wrap justify-center>
                <v-flex xs11>
                    <v-layout wrap justify-center>
                        <v-flex xs11 lg4 pl-4>
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs10>
                        <v-img src="../assets/Images/kingfisher.jpg"> </v-img>
                      </v-flex>
                    </v-layout>
                    <v-card class="popregular">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-layout wrap justify-center>
                                <v-flex xs11 pt-3 text-center>
                                  <span style="font-weight: bold"
                                    >Ex-Gratia support to Mahesh Soni
                                  </span>
                                </v-flex>
                                <v-flex pt-4 xs8 class="subheading">
                                  Who is Mahesh Soni?
                                </v-flex>
                                <v-flex xs1 pt-1>
                                  <v-expansion-panel-header>
                                  </v-expansion-panel-header>
                                </v-flex>
                              </v-layout>
                              <v-expansion-panel-content>
                                <v-layout wrap justify-center>
                                  <v-flex xs12 class="contentfont">
                                    Mahesh Soni serves as a Forester in the
                                    Betul Division of Maharashtra. He is 61 and
                                    due for retirement next year.
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="subheading">
                                What happened to Mahesh?
                              </v-expansion-panel-content>
                              <v-expansion-panel-content class="contentfont">
                                patrol, Mahesh got into a perilous situation
                                encountering a wood smuggler armed with an axe.
                                In his defense, Mahesh only had a stick. The
                                situation quickly escalated and the smuggler
                                struck Mahesh with the blunt end of his axe,
                                leaving him with a severe chest injury and 10
                                days of hospitalization. Swift intervention by
                                Forest staff saved his life, and WTI stepped in
                                to cover his medical expenses.
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="contentfont">
                                It was a close call, and he remarked, 'Had the
                                assailant used the sharp end of the axe, I would
                                have died.'
                              </v-expansion-panel-content>
                              <v-expansion-panel-content class="subheading">
                                Back to Work
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="contentfont">
                                Mahesh has recovered and is back to safeguarding
                                the forests he loves.
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs11 lg4 pl-4>
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs10>
                        <v-img src="../assets/Images/kingfisher.jpg"> </v-img>
                      </v-flex>
                    </v-layout>
                    <v-card class="popregular">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-layout wrap justify-center>
                                <v-flex xs11 pt-3 text-center>
                                  <span style="font-weight: bold"
                                    >Ex-Gratia support to Tilak Singh
                                  </span>
                                </v-flex>
                                <v-flex pt-4 xs8 class="subheading">
                                  Who is Tilak Singh?
                                </v-flex>
                                <v-flex xs1 pt-1>
                                  <v-expansion-panel-header>
                                  </v-expansion-panel-header>
                                </v-flex>
                              </v-layout>
                              <v-expansion-panel-content>
                                <v-layout wrap justify-center>
                                  <v-flex xs12 class="contentfont">
                                    Tilak Singh is a 49-year-old Forest Guard
                                    serving at the Betul Division of
                                    Maharashtra.
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>

                              <v-expansion-panel-content
                               class="subheading"
                              >
                                What happened to Tilak?
                              </v-expansion-panel-content>
                              <v-expansion-panel-content class="contentfont">
                                Following a tip-off from an informant, a forest
                                department team, including Tilak Singh, set out
                                to nab teak wood smugglers on 19 November 2022.
                                When confronted, the smugglers, in a desperate
                                attempt to escape, struck Tilak with their
                                speeding vehicle, causing him a severe head
                                injury.
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="contentfont">
                                He was rushed to Paratwada Hospital by the
                                forest department and later transferred to
                                Amravati Hospital. WTI’s immediate ex-gratia
                                support eased his medical bills
                              </v-expansion-panel-content>

                              <v-expansion-panel-content
                               class="subheading"
                              >
                                Back to Work
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="contentfont">
                                Tilak expressed gratitude for the timely
                                assistance allowing him to focus on a swift
                                recovery and return to his service.
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs11 lg4 pl-4>
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs10>
                        <v-img src="../assets/Images/kingfisher.jpg"> </v-img>
                      </v-flex>
                    </v-layout>
                    <v-card class="popregular">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-layout wrap justify-center>
                                <v-flex xs11 pt-3 text-center style="font-weight: bold;">
                                  <span 
                                    >Ex-Gratia support to Yadunandan
                                  </span>
                                </v-flex>
                                <v-flex pt-4 xs9 class="subheading">
                                  Who is Yadunandan Yadav?
                                </v-flex>
                                <v-flex xs1 pt-1>
                                  <v-expansion-panel-header>
                                  </v-expansion-panel-header>
                                </v-flex>
                              </v-layout>
                              <v-expansion-panel-content>
                                <v-layout wrap justify-center>
                                  <v-flex xs12 class="contentfont">
                                    In 1985, Yadunandan joined as a Peon on
                                    compassionate grounds after his father,
                                    serving as a Range Forest Officer (RFO),
                                    tragically lost his life on duty. His
                                    dedication resulted in his promotion to a
                                    full-time forester eventually.
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>

                              <v-expansion-panel-content
                                class="subheading"
                              >
                                What happened to Yadunandan?
                              </v-expansion-panel-content>
                              <v-expansion-panel-content class="contentfont">
                                On April 12, 2023, a team of 6-7 forest staff,
                                including Yadunandan, was activated following
                                information received on the movement of
                                tree-fellers along the Khomai-Andherbadi marg at
                                the MP-Maharashtra border. They encountered a
                                gang of 20, who resorted to stone pelting.
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="contentfont">
                                In the confrontation, Yadunandan lost his
                                balance and tumbled down the hilly terrain,
                                sustaining spinal injuries, for which he was
                                treated at the Suretech Hospital in Nagpur.
                              </v-expansion-panel-content>

                              <v-expansion-panel-content
                                class="subheading"
                              >
                                Back to Work
                              </v-expansion-panel-content>

                              <v-expansion-panel-content class="contentfont">
                                Immediate Ex-Gratia support from WTI helped him
                                in that difficult time. As the sole provider for
                                his family, he expresses gratitude for the
                                crucial financial assistance.
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
                    </v-layout>
                </v-flex>

              
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center >
          <v-flex xs11>
            <v-layout wrap justify-center pt-7>
              <v-flex xs11>
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12 align-self-center text-center py-4>
                        <span class="mainheadig"
                          >Testimonials</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 lg12 py-7 v-if="icons">
                    <v-carousel
                      cycle
                      :interval="10000"
                      height="auto"
                      hide-delimiter-background
                    >
                      <v-carousel-item v-for="(item, i) in icons" :key="i">
                        <v-card outlined>
                          <v-layout wrap pa-2 justify-center class="popregular">
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs6 sm6 md2 lg2 xl2 text-center>
                                  <v-avatar size="100%">
                                    <v-img :src="item.name"></v-img>
                                  </v-avatar>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex
                              xs10
                              style="font-size: 17px; font-weight: bolder"
                              text-center
                            >
                              <v-layout wrap justify-center>
                                <v-flex xs6 lg12  text-center>
                                  {{ item.heading }}
                                </v-flex>
                                <v-flex
                                  pl-3
                                  xs6
                                  lg12
                                  pt-2
                                
                                  text-center
                                  style="color: #e27725"
                                  >{{ item.location }}</v-flex
                                >
                                <v-flex
                                  xs11
                                  pt-4
                                  pt-3
                                  text-center
                                  style="font-size: 14px; text-align: justify"
                                  >{{ item.text }}</v-flex
                                >
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-carousel-item>
                    </v-carousel>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-16 id="faq-section">
            <v-layout wrap justify-center>
              <v-flex xs11>
                <span class="mainheadig"
                  >Frequently Asked Questions
                </span>
              </v-flex>

              <v-flex xs11 pt-2>
                <v-layout wrap>
                  <v-flex xs12 pt-5 style="text-align: justify">
                    <template>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="popregular medium"
                            style="
                              color: black;
                              font-weight: bold;
                              font-size: 16px;
                            "
                          >
                            What are the roles and responsibilities of frontline
                            forest staff?
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                            class="popregular medium"
                            style="color: black; font-size: 14px"
                          >
                            Frontline forest staff tirelessly patrol protected
                            areas, deterring illegal activities like poaching,
                            logging, and encroachment over vast land expanses of
                            1,200 to 3,700 acres. They actively prevent and
                            manage forest fires, engage in reforestation, and
                            collaborate with local communities for forest
                            conservation and sustainable management, averting
                            human-animal conflicts. Enforcing environmental
                            laws, they also gather crucial data on wildlife
                            population, habitat health, and ecological factors
                            to inform conservation efforts.
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-flex>
                  <v-flex xs12 pt-5 style="text-align: justify">
                    <template>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="popregular medium"
                            style="
                              color: black;
                              font-weight: bold;
                              font-size: 16px;
                            "
                          >
                            What are the challenges faced by the frontline
                            forest staff in India?
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                            class="popregular medium"
                            style="color: black; font-size: 14px"
                          >
                            Frontline staff face constant threats from
                            well-armed poachers and wild animal encounters while
                            enduring the impacts of changing weather patterns
                            and wildfires. They operate with limited resources
                            in remote, physically demanding locations, often
                            lacking basic amenities and healthcare. They grapple
                            with rising human- animal conflicts and insufficient
                            support from communities. Disconnected from their
                            families for weeks, these unsung heroes - including
                            temporary staff - receive inadequate compensation,
                            potentially demotivating their dedication to
                            preserving India&#39;s natural heritage.
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-flex>
                  <v-flex xs12 pt-5 style="text-align: justify">
                    <template>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="popregular medium"
                            style="
                              color: black;
                              font-weight: bold;
                              font-size: 16px;
                            "
                          >
                            What does WTI do to support India’s frontline forest
                            staff?
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                            class="popregular medium"
                            style="color: black; font-size: 14px"
                          >
                            WTI provides frontline forest staff in India with
                            regular training and essential equipment support. As
                            the pioneer Indian NGO offering a Supplementary
                            Accident Assurance Scheme, WTI covers forest staff
                            against accidental death or injury on duty,
                            providing immediate financial support for medical
                            bills and assisting their families in those
                            challenging times. This holistic approach aims to
                            stand in support and uplift the morale of these
                            unsung guardians of the wild.
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-flex>
                  <v-flex xs12 pt-5 style="text-align: justify">
                    <template>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="popregular medium"
                            style="
                              color: black;
                              font-weight: bold;
                              font-size: 16px;
                            "
                          >
                            How many frontline forest staff are covered under
                            WTI’s ex-gratia scheme?
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                            class="popregular medium"
                            style="color: black; font-size: 14px"
                          >
                            WTI&#39;s ex-gratia scheme covers all frontline
                            forest staff in India up to the rank of Range
                            Officer, and encompasses both permanent and
                            contractual daily wage earners.
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-flex>
                  <v-flex xs12 pb-10 pt-5 style="text-align: justify">
                    <template>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="popregular medium"
                            style="
                              color: black;
                              font-weight: bold;
                              font-size: 16px;
                            "
                          >
                            How will my contribution be utilised?
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                            class="popregular medium"
                            style="color: black; font-size: 14px"
                          >
                            WTI ensures financial transparency, directing every
                            donation to our program offering ex-gratia support
                            to frontline forest staff. Your contribution aids
                            injured staff or families of those who have
                            tragically lost their lives on duty, covering
                            medical expenses and providing essential assistance.
                            As you honour their dedication and sacrifice in
                            safeguarding India’s forests and wildlife, we
                            appreciate your impactful contribution.<br /><br />
                            Stay informed about our initiatives by subscribing
                            to the WTI Newsletter.
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout
              class="popregular medium"
              style="color: black; font-size: 14px; text-align: justify"
              pb-10
              wrap
              justify-center
            >
              <v-flex xs11 text-center pt-8 id="featuredSupportSection">
                <span style="font-weight: bold;color:#E27B25" class="mainheadig"
                  >Featured Support
                </span>
              </v-flex>
              <v-flex xs11 pt-3>
                <span>
                  Varadaraj&#39;s family migrated from Tamil Nadu to
                  Kerala&#39;s verdant paradise – Tholpetty in Wayanad when he
                  was just a toddler. In this pristine hilly terrain, teeming
                  with lush forests and wilderness, Varadaraj spent most of his
                  childhood. Attending school until the second grade, he grew
                  into supporting the forest department with daily labor tasks,
                  including maintenance and installation of fences, clearing
                  invasive plants, logging, digging trenches, and handling
                  forest fire emergencies.
                </span>
                <br />
                <br />
                <span>
                  Recognizing his long-term dedication, sincere work, and
                  wildlife knowledge, Varadaraj was appointed as a daily wage
                  watcher in 2017. Alongside his existing activities, he joined
                  the forest patrolling team. In this region where humans and
                  wildlife live closely, Varadaraj and the forest department
                  team play a vital role as de facto peacekeepers, mitigating
                  human-wildlife conflicts, guiding elephants, tigers, and other
                  wildlife away from human habitations, and guarding against
                  poachers.
                </span>
                <br />
                <br />

                <span>
                  Except for brief rest periods, Varadaraj remains vigilant even
                  during the day, managing his duties from a bamboo hut near the
                  sanctuary.
                </span>
              </v-flex>
              <v-flex xs12 lg11 pa-6>
                <v-layout wrap justify-center>
                  <v-flex xs12 lg5>
                    <v-img src="../assets/Images/gratiaimage1.jpg"> </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs11>
                <span>
                  In 1985, Mr. Yadav was employed on compassionate grounds,
                  starting as a humble peon. His determination and dedication
                  saw him rise through the ranks, eventually becoming a full-
                  time Forester in the south-Betul Division, a role that demands
                  both commitment and courage. His duties are multifaceted,
                  encompassing patrolling the forests to protect them from
                  illegal activities and fulfilling various official
                  responsibilities at the department office. </span
                ><br /><br />
                <span>
                  On the fateful night of April 12, 2023, the forest department
                  received information that intruders from Maharashtra were
                  planning to fell trees along the Khomai-Andherbadi marg at the
                  MP-Maharashtra border. Armed with only a stick and an axe for
                  his defense, Mr. Yadav accompanied a team of 6-7 staff to the
                  scene. At 6:30 the next morning, when most of us were still in
                  the embrace of sleep at home, they encountered 20 individuals
                  exiting the forest with stolen branches. When the team
                  attempted to intervene, the fellers resorted to violence,
                  pelting stones at the officials.
                </span>
              </v-flex>

              <v-flex xs11 pt-7>
                <span style="font-weight: bold;font-size: 15px;" class="popregular ml"
                  >On the night of the fateful incident
                </span>
              </v-flex>

              <v-flex xs11 pt-6>
                <span>
                  Adept at handling firecrackers, Vardaraj uses them carefully
                  to deter elephants from damaging crops and property, guiding
                  them back into the safety of forests. However, on the fateful
                  night of the incident, a cracker flung by Varadaraj to usher
                  away a lone tusker hit a gate and bounced back toward his
                  stomach.
                </span>
              </v-flex>

              <v-flex xs12 lg11 pa-6>
                <v-layout wrap justify-center>
                  <v-flex xs12 lg5>
                    <v-img src="../assets/Images/gratiaimage2.jpg"> </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs11>
                <span>
                  Despite low visibility and being alone with a torchlight, he
                  quickly dodged the firecracker with his hand, saving himself
                  from a more severe stomach injury but rupturing his finger,
                  which bled profusely.
                </span>
              </v-flex>

              <v-flex xs12 lg11 pa-6>
                <v-layout wrap justify-center>
                  <v-flex xs12 lg5>
                    <v-img src="../assets/Images/gratiaimage3.jpg"> </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs11 pt-6>
                <span>
                  Rushed to Government Medical College, Mananthwady, by forest
                  department staff, the ring finger of his left hand had to be
                  amputated. WTI immediately sanctioned ex-gratia support to
                  cover his medical expenses.
                </span>
              </v-flex>
              <v-flex xs11 pt-6>
                <span>
                  After the injury, Varadaraj worried about providing for his
                  family, consisting of two daughters and his wife. It took him
                  nearly two months to overcome the initial shock and for the
                  pain to subside, but he is grateful for the support from the
                  forest department and WTI for helping him through this tragic
                  incident.
                </span>
              </v-flex>

              <v-flex xs12 lg11 pa-6>
                <v-layout wrap justify-center>
                  <v-flex xs12 lg5>
                    <v-img src="../assets/Images/gratiaimage4.jpg"> </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs11 pt-6>
                <span>
                  Today, Varadaraj has re-joined duty, continuing to guard the
                  forests he loves. He mentions, “My left hand no longer has the
                  same strength, and it still hurts due to the surgery, but I
                  believe I will get better eventually.”
                </span>
              </v-flex>

              <v-flex xs11 pt-6>
                <span>
                  Your support is critical for providing immediate relief to the
                  forest staff and temporary wagers like Vardaraj during such
                  challenging times. We owe this support to these unsung heroes
                  – the guardians of the wild – who are risking their lives
                  every day to protect India’s natural heritage.
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Newaddress: "",
      Newcity: "",
      icons: [
        {
          name: require("../assets/Images/account.jpg"),
          heading: "Emily Turner",
          location: "WTI Donor",
          text: '"I feel honoured to be a part of WTIs initiative to support the frontline forest staff and their families. Its heart-warming to see an organization take such concrete steps to ensure the well-being of those who protect our natural heritage. Knowing that our contributions are making a difference in the lives of these brave individuals and their loved ones is truly fulfilling."',
        },
        {
          name: require("../assets/Images/account.jpg"),
          heading: "Mark Anderson",
          location: "WTI Donor",
          text: '"Supporting this initiative has been a deeply rewarding experience. The commitment WTI shows to the welfare of the frontline staff is truly remarkable. Its an investment in the people who are on the frontlines safeguarding our forests, wildlife, and the environment. Im proud to be a part of this initiative, knowing that our contributions are providing immediate help to those who need it most.""',
        },
        {
          name: require("../assets/Images/account.jpg"),
          heading: "Sarah Davis",
          location: "WTI Donor",
          text: '"It’s a privilege to be a part of this community that supports the brave frontline forest staff and their families. WTIs dedication to their welfare is evident, and Im inspired by the positive impact we can make together."',
        },
      ],
      Newpincode: "",
      Newpannumber: "",
      items: [
        {
          imageUrl: "your-image-1.jpg",
          title: "Slide 1",
          description: "Description for Slide 1",
        },
        {
          imageUrl: "your-image-2.jpg",
          title: "Slide 2",
          description: "Description for Slide 2",
        },
        // Add more items as needed
      ],
      //inherited varibles from donation Card
      BTN1: 5000,
      BTN2: 10000,
      BTN3: 20000,
      sign: "₹",
      Cname: "INR",

      country: "India",
      amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      taxExemption: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],

      //end of inherited variables from donationCard
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      donation: 5000,
      firstCard: true,
      expansionItems: [
        { title: "Item 1", content: "Content for Item 1" },
        { title: "Item 2", content: "Content for Item 2" },
        // Add more items as needed
      ],
      secondCard: false,
      radio: null,
      showSnackBar: false,
      msg: "",
      ServerError: false,
      timeout: 3000,
      appLoading: false,
    };
  },
  watch: {
    $route(to) {
      // Watch for changes in the route's query parameters
      if (to.query.scrollTo === "ExGratia") {
        this.scrollToExGratia();
      }
    },
    country() {
      if (this.country != "India") {
        this.BTN1 = 100;
        this.BTN2 = 200;
        this.BTN3 = 500;
        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 5000;
        this.BTN2 = 10000;
        this.BTN3 = 20000;
        this.sign = "₹";
        this.Cname = "INR";

        this.donation = this.BTN1;

        //  console.log("false india")
      }
    },
  },
  mounted() {
    console.log("Page2 mounted");
    this.scrollToExGratia();
  },
  beforeMount() {
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  methods: {
    scrollToExGratia() {
      // Using vanilla JavaScript to scroll to the target element
      const exGratiaElement = this.$refs.exGratia;

      if (exGratiaElement) {
        exGratiaElement.scrollIntoView({ behavior: "smooth" });
      }
    },

    login() {
      this.$gtag.event("login", { method: "Google" });
    },

    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },
    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        // don["donationtype"] = this.$route.query.DonationName;
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        // don["pincode"] = this.Newpincode;
        // don["panNumber"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;

        // taxExemption
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/payment/?id=" +
                  response.data.id +
                  "&country=" +
                  this.country +
                  "&event=" +
                  this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "Please complete your form";
      }
    },
  },
};
</script>
<style  scoped>
.LBL >>> label {
  font-family: poppinsregular !important;
  font-size: 12px !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: grey;
  border-radius: 5px;
}

.v-radio >>> label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}

.center >>> input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}

.center2 >>> label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 20px;
}

.center4 {
  text-align: center !important;
  font-family: poppinsregular !important;
}

.boldR >>> label {
  font-family: poppinsbold;
}

.animate {
  position: relative;
  animation: mymove 0.25s;
}

.animate2 {
  position: relative;
  animation: mymove2 0.25s;
}

#div2 {
  animation-timing-function: linear;
}

@keyframes mymove2 {
  from {
    left: -200px;
  }

  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: -500px;
  }

  to {
    right: 0px;
  }
}

#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear;
  /* Safari */
  animation: spin 2s linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.v-checkbox >>> label {
  font-family: poppinsregular;
}

.avatar-container {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.subheading {
  font-family: poppinsregular;
  font-weight: bold;
  font-size: 16px;
  color: #3d673a;
}
.mainheadig {
  font-family: poppinsregular;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.contentfont {
  font-family: poppinsregular;
  font-size: 14px;
  color: black;
}

.card {
  margin-top: 50px;
  /* Adjust the margin to control the distance between the avatar and card */
  z-index: 1;
}
</style>